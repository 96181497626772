import * as Sentry from '@sentry/browser';
import { SENTRY_ENVIRONMENT, SENTRY_IO_DSN, SENTRY_RELEASE } from './../constants';

/** This file is for the initialization of sentry
   and for the management of the growing number of filters
   which we want to keep out of the main app
   */

const DEVELOPMENT = 'development';

const REJECTED_WITH_STACK = Object.freeze([/chrome-extension:/i, /google-maps-script-loader/i]);
const REJECTED_WITH_MESSAGE = Object.freeze([/ceCurrentVideo.currentTime/i]);

export const configureSentry = () => {
  const isDevelopment = SENTRY_ENVIRONMENT === DEVELOPMENT;
  Sentry.init({
    dsn: SENTRY_IO_DSN,
    environment: SENTRY_ENVIRONMENT,
    release: SENTRY_RELEASE,
    debugger: isDevelopment, // trigger DevTools debugger instead of using console.log
    stringify: isDevelopment, // stringify event before passing it to console.log

    beforeSend: function (event, hint) {
      const error = hint.originalException;

      let reject = false;

      if (error && error.stack) {
        REJECTED_WITH_STACK.forEach((reg) => {
          if (error.stack.match(reg)) {
            reject = true;
          }
        });
      }

      if (error && error.message) {
        REJECTED_WITH_MESSAGE.forEach((reg) => {
          if (error.message.match(reg)) {
            reject = true;
          }
        });

        if (error.message.match(/loading.+chunk/i)) {
          event.fingerprint = ['chunk-load-error'];
        }
      }

      if (reject === true) {
        return null;
      }

      if (isDevelopment) {
        return console.debug('SENTRY EVENT:', event);
      }

      return event;
    },
  });
};
