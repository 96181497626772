import { deepFreeze } from './lib/deepFreeze';
import { MemoryStorage } from './storage/memory';

export const AWS_REGION = process.env['REACT_APP_AWS_REGION'];
export const AWS_USER_POOL_ID = process.env['REACT_APP_AWS_USER_POOL_ID'];
export const AWS_USER_POOL_WEB_CLIENT_ID = process.env['REACT_APP_AWS_USER_POOL_WEB_CLIENT_ID'];
export const AWS_COOKIE_DOMAIN = process.env['REACT_APP_AWS_COOKIE_DOMAIN'];

export const AWS_AMPLIFY_CONFIG = Object.freeze({
  region: AWS_REGION,
  userPoolId: AWS_USER_POOL_ID,
  userPoolWebClientId: AWS_USER_POOL_WEB_CLIENT_ID,
  storage: MemoryStorage,
});

export const configureAuth = (storageProvider) => {
  return Object.freeze({
    region: AWS_REGION,
    userPoolId: AWS_USER_POOL_ID,
    userPoolWebClientId: AWS_USER_POOL_WEB_CLIENT_ID,
    storage: storageProvider,
  });
};

export const LINK_TRIGGER = '#givingflow';
export const LINK_PARAMS_KEYS = deepFreeze(['amount', 'fund', 'interval', 'interval_start']);

export const PLATFORM_API_BASE_URL = process.env['REACT_APP_PLATFORM_API_BASE_URL'];
export const GIVING_API_BASE_URL = process.env['REACT_APP_GIVING_API_BASE_URL'];
export const GIVING_FLOW_BASE_URL = process.env['REACT_APP_GIVING_FLOW_BASE_URL'];
export const CONTROL_CENTER_BASE_URL = process.env['REACT_APP_CONTROL_CENTER_BASE_URL'];
export const MARKETING_SITE_BASE_URL = process.env['REACT_APP_MARKETING_SITE_BASE_URL'];

export const SENTRY_ENVIRONMENT = process.env['REACT_APP_SENTRY_ENVIRONMENT'] || 'development';
export const SENTRY_RELEASE = process.env['REACT_APP_COMMIT_REF'];
export const SENTRY_IO_DSN =
  process.env['REACT_APP_SENTRY_IO_DSN'] || 'https://d6d9f880441943b5a69c0b4ff5f7b3a6@sentry.io/1489256';

export const REBELPAY_API_BASE_URL = process.env['REACT_APP_REBELPAY_API_BASE_URL'];
export const REBELPAY_API_KEY_PUBLIC = process.env['REACT_APP_REBELPAY_API_KEY_PUBLIC'];

export const TOKEN_EXPIRATION_BUFFER = 15 * 1000;
export const TOKEN_CREATION_KEY_ACH = 'bank_account_ach';
export const TOKEN_CREATION_KEY_EFT = 'bank_account_eft'; // NOT SUPPORTED YET.
export const TOKEN_CREATION_KEY_CARD = 'card';
export const TOKEN_CREATION_KEY_PLAID = 'plaid_token';

export const GIVEN_AT_WINDOW_MINUTES = parseInt(process.env['REACT_APP_GIVEN_AT_WINDOW_MINUTES'] || '120');

export const PLAID_CLIENT_FRAGMENT = process.env['REACT_APP_PLAID_CLIENT_FRAGMENT'];
export const PLAID_ENV = process.env['REACT_APP_PLAID_ENV'];

export const BANK_ACCOUNT_TYPE_CHECKING = 'GL';
export const BANK_ACCOUNT_TYPE_SAVINGS = 'SAVINGS';

export const SOURCE_TYPE_ACH_DEBIT = 'ach_debit';
export const SOURCE_TYPE_CARD = 'card';

export const SOURCE_TYPES = deepFreeze({
  [SOURCE_TYPE_ACH_DEBIT]: {
    name: 'Bank Account',
    name_short: 'Bank',
  },
  [SOURCE_TYPE_CARD]: {
    name: 'Credit Card',
    name_short: 'Card',
  },
});

export const MESSAGE_GIVINGFLOW_CLOSE_CLICKED = 'MESSAGE_GIVINGFLOW_CLOSE_CLICKED';
export const MESSAGE_GIVINGFLOW_LOADED = 'MESSAGE_GIVINGFLOW_LOADED';
export const MESSAGE_GIVINGFLOW_TRIGGER_CLICKED = 'MESSAGE_GIVINGFLOW_TRIGGER_CLICKED';
export const MESSAGE_GIVINGFLOW_TRIGGER_LOADED = 'MESSAGE_GIVINGFLOW_TRIGGER_LOADED';
export const MESSAGE_LOAD_MEMORY_STORAGE = 'MESSAGE_LOAD_MEMORY_STORAGE';
export const MESSAGE_RECEIVE_CONFIG = 'MESSAGE_RECEIVE_CONFIG';
export const MESSAGE_SAVE_MEMORY_STORAGE = 'MESSAGE_SAVE_MEMORY_STORAGE';
export const MESSAGE_TYPE_MEMORY_STORAGE = 'MESSAGE_TYPE_MEMORY_STORAGE';

export const EMAIL_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

export const REDUX_STATE_KEY = '__givingflow_redux_state';
export const REDUX_STATE_VERSION = 2; // Advance me when required to invalidate bad state
export const REDUX_STATE_EXPIRES_AFTER = 12 * 60 * 60 * 1000; // 12 hours

export const SELECTED_INTERVAL_EXPIRES_AFTER = 3 * 60 * 60 * 1000; // 3 hours

export const GIVINGFLOW_LS_STATE_KEY = '__givingflow_state';
export const PERSISTENT_KEYS = deepFreeze([
  'completedSteps',
  'convenienceFee',
  'currentStep',
  'givenAt',
  'selectedAmount',
  'selectedDesignation',
  'selectedEmail',
  'selectedInterval',
  'selectedMemo',
  'selectedName',
  'selectedPaymentSource',
  'selectedPaymentType',
  'selectedScheduleStartOption',
  'tempPaymentSources',
  'usingSavedPaymentSource',
]);

export const DEFAULT_PALLETTE = deepFreeze({
  Dark1: 'transparent',
  Dark2: 'transparent',
  Light: 'transparent',
  Vibrant1: 'transparent',
  Vibrant2: 'transparent',
});

export const STEP = deepFreeze({
  GET_AMOUNT: 'get_amount',
  GET_BANK_ACCOUNT: 'get_bank_account',
  GET_CREDIT_CARD: 'get_credit_card',
  GET_DESIGNATION: 'get_designation',
  GET_EMAIL: 'get_email',
  GET_INTERVAL: 'get_interval',
  GET_MEMO: 'get_memo',
  GET_NAME: 'get_name',
  GET_PAYMENT_INFO: 'get_payment_info',
  GET_PAYMENT_TYPE: 'get_payment_type',
  GET_PLAID: 'get_plaid',
  GET_SCHEDULE_START: 'get_schedule_start',
  GET_SUMMARY: 'get_summary',
});

export const STEPS = deepFreeze([
  STEP.GET_AMOUNT,
  STEP.GET_DESIGNATION,
  STEP.GET_INTERVAL,
  STEP.GET_SCHEDULE_START,
  STEP.GET_PAYMENT_TYPE,
  STEP.GET_PAYMENT_INFO,
  STEP.GET_MEMO,
  STEP.GET_NAME,
  STEP.GET_EMAIL,
  STEP.GET_SUMMARY,
]);

export const INTERVAL = deepFreeze({
  WEEK: 'INTERVAL_WEEK',
  MONTH: 'INTERVAL_MONTH',
  DATES: 'INTERVAL_DATES',
});

/* INTERVALS: logic related to making transactions and giftschedules.
 * maxCharges: Optional. Sets 'duration' (number of times a schedule can be related
 *              to a successful transaction). Currently useful for scheduled one-time
 *              gift. Example: scheduled one-time gift would use INTERVAL_DATES type;
 *              with intervalDates (array having only date of startDate); startDate;
 *              and maxCharges of 1.
 * intervalNow: If true, will create an immediate charge on any scheduled gift - regardless
 *              of intervalDates or startDate. For Backwards compatibility, it is not
 *              required for one-time, INTERVAL_WEEK, or INTERVAL_MONTH gifts which
 *              start today by default. Because client timezone may differ from UTC,
 *              it is required with INTERVAL_DATES, for the client to use this value
 *              if today's date is in the intervalDates array. It is also the expectation
 *              of the person to be charged immediately if "today" is one of the interval dates.
 * startDate:   Js Timestamp. Sets the interval anchor to say: "no matter what, this
 *              is the 'interval_anchor' and 'next_charge_at' for the gift schedule".
 *              whereas the transaction creation will complete a transaction immediately
 *              when intervalNow is true; the giftschedule processing cron job will
 *              act on this startDate, if set. This allows for any custom schedule or interval,
 *              to charge on this date, and set the next interval based upon it.
 *              Currently, the server will accept a date up to three months future.
 *              [NOTE: startDate and intervalNow are not mutually exclusive,
 *              they could both be used at the same time to "make a gift now", then
 *              "make a gift on this startDate, and thereafter according to the
 *              schedule".] Also, note that developer will need to programmatically ensure
 *              the future startDate is on the desired interval, if the intervalType is
 *              INTERVAL_DATES - or, as otherwise desired, because this will schedule a
 *              charge as of the startDate. For INTERVAL_DATES, the developer needs to
 *              programmatically ensure that the future startDate is on a desired interval,
 *              only if the desired start date is beyond one of the next dates in the array).
 *              Don't pass a startDate value for today! - That would not give the immediate
 *              charge, as might be thought, it would rather charges it when the processing
 *              cron job ran - not a good user experience for charging today -
 *              see intervalNow and above comments.
 * intervalZone: new argument send with api request body that helps us communicate the dates
 *              appropriately to the person. also helps server set nextInterval "date number"
 *              correctly from the standpoint of the person who started this schedule. We are
 *              passing this as a value for each request, but it is only stored on the
 *              server when it is needed for GiftScheduling. In some email communication,
 *              we are still sending UTC times to Givers, but when it comes to formatting
 *              day of week or date of month, we can use this timezone string.
 */

export const SCHEDULE_START_DATE_MAXIMUM_FUTURE_MONTHS = 9;

export const INTERVAL_JUST_THIS_ONCE = 'Just this once';
export const INTERVAL_EVERY_FIRST_AND_FIFTEENTH = 'Every 1st and 15th';
export const INTERVAL_EVERY_FIFTH_AND_TWENTIETH = 'Every 5th and 20th';
export const INTERVAL_EVERY_MONTH = 'Every month';
export const INTERVAL_EVERY_TWO_WEEKS = 'Every two weeks';
export const INTERVAL_EVERY_WEEK = 'Every week';

export const QUICK_INTERVALS = deepFreeze([INTERVAL_EVERY_FIRST_AND_FIFTEENTH, INTERVAL_EVERY_FIFTH_AND_TWENTIETH]);

export const INTERVALS = deepFreeze([
  {
    id: 0,
    name: INTERVAL_EVERY_WEEK,
    intervalType: INTERVAL.WEEK,
    intervalCount: 1,
    intervalDates: undefined,
    maxCharges: undefined,
    intervalNow: undefined,
    startDate: undefined,
  },
  {
    id: 1,
    name: INTERVAL_EVERY_TWO_WEEKS,
    intervalType: INTERVAL.WEEK,
    intervalCount: 2,
    intervalDates: undefined,
    maxCharges: undefined,
    intervalNow: undefined,
    startDate: undefined,
  },
  {
    id: 2,
    name: INTERVAL_EVERY_MONTH,
    intervalType: INTERVAL.MONTH,
    intervalCount: 1,
    intervalDates: undefined,
    maxCharges: undefined,
    intervalNow: undefined,
    startDate: undefined,
  },
  {
    id: 3,
    name: INTERVAL_EVERY_FIRST_AND_FIFTEENTH,
    intervalType: INTERVAL.DATES,
    intervalCount: undefined,
    intervalDates: [1, 15],
    maxCharges: undefined,
    intervalNow: undefined,
    startDate: undefined,
  },
  {
    id: 4,
    name: INTERVAL_EVERY_FIFTH_AND_TWENTIETH,
    intervalType: INTERVAL.DATES,
    intervalCount: undefined,
    intervalDates: [5, 20],
    maxCharges: undefined,
    intervalNow: undefined,
    startDate: undefined,
  },
  {
    id: 5,
    name: INTERVAL_JUST_THIS_ONCE,
    style: 'secondary',
    intervalType: undefined,
    intervalCount: undefined,
    intervalDates: undefined,
    maxCharges: undefined,
    intervalNow: undefined,
    startDate: undefined,
  },
]);
// group of intervals to offer "today, tomorrow" kind of schedule start
export const INTERVALS_SCHEDULED_BY_DATE = deepFreeze([
  INTERVAL_EVERY_WEEK,
  INTERVAL_EVERY_TWO_WEEKS,
  INTERVAL_EVERY_MONTH,
  INTERVAL_JUST_THIS_ONCE,
]);
// group of intervals to offer "this month, next month" start options
export const INTERVALS_SCHEDULED_BY_DATES = deepFreeze([
  INTERVAL_EVERY_FIRST_AND_FIFTEENTH,
  INTERVAL_EVERY_FIFTH_AND_TWENTIETH,
]);
export const SCHEDULE_CUSTOM = 'Custom';
export const SCHEDULE_TODAY = 'Today';
export const SCHEDULE_TOMORROW = 'Tomorrow';
export const SCHEDULE_THIS_FRIDAY = 'This Friday';
export const SCHEDULE_NEXT_FRIDAY = 'Next Friday';
export const SCHEDULE_THIS_SUNDAY = 'This Sunday';
export const SCHEDULE_NEXT_SUNDAY = 'Next Sunday';
export const SCHEDULE_THIS_MONTH = 'This month';
export const SCHEDULE_NEXT_MONTH = 'Next month';
// control center may pass "Friday" as the trigger start
export const SCHEDULE_FRIDAY = 'Friday';
// control center may also send "Next week" as a schedule
export const SCHEDULE_NEXT_WEEK = 'Next week';
export const SCHEDULES = deepFreeze([
  SCHEDULE_TODAY,
  SCHEDULE_TOMORROW,
  SCHEDULE_NEXT_FRIDAY,
  SCHEDULE_THIS_FRIDAY,
  SCHEDULE_THIS_SUNDAY,
  SCHEDULE_NEXT_SUNDAY,
  SCHEDULE_THIS_MONTH,
  SCHEDULE_NEXT_MONTH,
]);

export const SUNDAY = 'Sunday';
export const MONDAY = 'Monday';
export const TUESDAY = 'Tuesday';
export const WEDNESDAY = 'Wednesday';
export const THURSDAY = 'Thursday';
export const FRIDAY = 'Friday';
export const SATURDAY = 'Saturday';
export const DAYS_OF_WEEK = deepFreeze([SUNDAY, MONDAY, TUESDAY, WEDNESDAY, THURSDAY, FRIDAY, SATURDAY]);

export const GIVING_FLOW_COLOR_MODE_LIGHT = 'GIVING_FLOW_COLOR_MODE_LIGHT';
export const GIVING_FLOW_COLOR_MODE_DARK = 'GIVING_FLOW_COLOR_MODE_DARK';
export const GIVING_FLOW_COLOR_MODES = deepFreeze([GIVING_FLOW_COLOR_MODE_LIGHT, GIVING_FLOW_COLOR_MODE_DARK]);

export const GIVING_FLOW_BUTTON_POSITION_LEFT = 'GIVING_FLOW_BUTTON_POSITION_LEFT';
export const GIVING_FLOW_BUTTON_POSITION_RIGHT = 'GIVING_FLOW_BUTTON_POSITION_RIGHT';
export const GIVING_FLOW_BUTTON_POSITIONS = deepFreeze([
  GIVING_FLOW_BUTTON_POSITION_LEFT,
  GIVING_FLOW_BUTTON_POSITION_RIGHT,
]);

export const MEMO_MAX_LENGTH = 140;

export const EMAIL_STATUS = {
  LOGIN: 'Login',
  SIGNUP: 'Sign Up',
  NOSIGNUP: 'Cannot Sign Up',
  PASSWORDRESET: 'Password Reset',
};
