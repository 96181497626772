import React, { Suspense } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import RedirectToMarketingSite from './components/RedirectToMarketingSite';
import './css/';
import { retry } from './lib/retry';

const Frame = React.lazy(() => retry(() => import('./Frame')));
const Hosted = React.lazy(() => retry(() => import('./Hosted')));
const Trigger = React.lazy(() => retry(() => import('./Trigger')));

export default () => {
  return (
    <Router>
      <Suspense fallback={null}>
        <Switch>
          <Route exact path="/" component={RedirectToMarketingSite} />
          <Route exact path="/trigger" component={Trigger} />
          <Route path="/:shortcode/frame" component={Frame} />
          <Route path="/:shortcode" component={Hosted} />
        </Switch>
      </Suspense>
    </Router>
  );
};
