import { useEffect } from 'react';
import { MARKETING_SITE_BASE_URL } from '../constants';

export default () => {
  useEffect(() => {
    window.location = MARKETING_SITE_BASE_URL;
  }, []);

  return null;
};
